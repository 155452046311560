"use client";
import { FC, ReactNode, useRef } from "react";
import { cn } from "@/utils/cn";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor?: "light-yellow" | "dark-gray";
  size?: "small" | "medium" | "large";
  color?: "purple" | "yellow" | "white" | "dark-gray";
  before?: ReactNode;
  after?: ReactNode;
}

export const Button: FC<ButtonProps> = ({ children, color, size, before, after, ...props }) => {
  const rippleRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const sizeClasses = {
    small: "h-8 px-4 sm:px-3 text-xl gap-1",
    medium: "h-12 px-6 sm:px-5 text-2xl gap-2",
    large: "h-16 px-4 px-10 py-10 px-7 text-xl sm:text-2xl md:text-3xl gap-3",
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ripple = rippleRef.current;
    if (!ripple) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
  };

  const handleMouseEnter = () => {
    if (!rippleRef.current || !buttonRef.current) return;
    rippleRef.current.style.width = buttonRef.current?.offsetWidth * 1.5 + "px";
    rippleRef.current.style.height = buttonRef.current?.offsetWidth * 1.5 + "px";
  };

  const handleMouseLeave = () => {
    if (!rippleRef.current) return;
    rippleRef.current.style.width = "0px";
    rippleRef.current.style.height = "0px";
  };

  return (
    <button
      {...props}
      ref={buttonRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        handleMouseLeave();
        if (props.onClick) props.onClick(e);
      }}
      className={cn(
        "relative overflow-hidden rounded-full",
        {
          "bg-violet-600 text-white": color === "purple",
          "bg-light-yellow text-dark-gray": color === "yellow" || !color,
          "bg-white text-dark-gray": color === "white",
          "bg-dark-gray text-white": color === "dark-gray",
          "opacity-50 cursor-not-allowed": props.disabled,
        },
        sizeClasses[size || "medium"],
        props.className
      )}
    >
      <div
        ref={rippleRef}
        className={cn(
          "z-0 absolute w-0 h-0 -translate-x-1/2 -translate-y-1/2 rounded-full transform transition-[width,height] duration-500 ease-in-out",
          {
            "bg-white": color === "purple" || color === "yellow" || !color,
            "bg-black": color === "white" || color === "purple",
            "bg-purple": color === "dark-gray",
          }
        )}
      ></div>
      <div className="z-10 relative flex justify-center items-center w-full h-full gap-5">
        {before && <div className="flex-shrink-0">{before}</div>}
        {children}
        {after && <div className="flex-shrink-0">{after}</div>}
      </div>
    </button>
  );
};
